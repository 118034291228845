import Layout from "../comps/container.jsx";
import ButtonRep from "../comps/buttonrep";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Text,
} from "@nextui-org/react";

function App(props) {
    const navigate = useNavigate();
    return (
        <Layout>
            <Container css={{ mt: "50px", textAlign: "center" }}>
                <Text h2 size="40px" css={{textGradient: "-70deg, #3b3eff -10%, #aa49ff 50%, #f530ff 100%"}}>
                    All my links
                </Text>
                <Text css={{ color: "$gray700", mt: "-10px" }}>
                    Some other socials you can find me at.
                </Text>
                <Container css={{ maxWidth: "400px", mb: "600px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <ButtonRep click={() => window.open('https://www.bahpu.co')} name="My Main Website / Portfolio"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.youtube.com/channel/UCJS-ti7bEyDThIxyI0uwjdg')} name="Alt YouTube"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.youtube.com/@bahpu_')} name="Main YouTube"></ButtonRep>
                    <ButtonRep click={() => window.open('https://twitter.com/Bahpu_')} name="Twitter"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.twitch.tv/bahpu_')} name="Twitch"></ButtonRep>
                    <ButtonRep click={() => window.open('https://steamcommunity.com/id/Bahpz')} name="Steam Account"></ButtonRep>
                </Container>
            </Container>
        </Layout>
    );
}

export default App;
