import { createTheme, NextUIProvider } from "@nextui-org/react";
import { useEffect } from "react";
import Background from "./background.jsx";
import "./assets/css/global.css";

import { useLocation, Routes, Route } from "react-router-dom";
import Home from "./components/home/home";
import Editing from "./components/editing/editing";
import Mapping from "./components/mapping/mapping";
import Thumbnails from "./components/thumbnails/thumbnails";
import Projects from "./components/projects/projects";
import Reps from "./components/rep/reps";
import Links from "./components/links/allmylinks";
import Test from "./components/test/test.jsx";
import TestAgain from "./components/test2/testagain.jsx";
import Test3 from "./components/test3/test3.jsx";

const theme = createTheme({
  type: "dark",
  theme: {
    colors: {
      background: "#181b1d",
      primaryLight: "#ff4801",
      primaryLightHover: "#ff6c24",
      primaryLightActive: "#ff8e44",
      primaryLightContrast: "#ffb882",
      primary: "#24c963",
      primaryBorder: "#ffa566",
      primaryBorderHover: "#ffb882",
      primarySolidHover: "#ffc796",
      primarySolidContrast: "$white",
      primaryShadow: "#ffa566",
      customColorDog: "#FF38a8",
      gradientGreen: "linear-gradient(270deg, #00ffd0, #00ff1e, #00ff1e)",
      link: "#5E1DAD",
      gradient:
        "linear-gradient(112deg, #0062ff -25%, #ff00ff -10%, #4c00ff 80%)",
      gradientValue: "112deg, #0062ff -25%, #ff00ff -10%, #4c00ff 80%",
      gradientBlurple: "linear-gradient(90deg, #0062ff, #ff00ff, #4c00ff)",
      gradientBlurple_R: "linear-gradient(270deg, #0062ff, #ff00ff, #4c00ff)",
    },
  },
});

function App(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <NextUIProvider theme={theme}>
      <Background />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thumbnails" element={<Thumbnails />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/mapping" element={<Mapping />} />
        <Route path="/editing" element={<Editing />} />
        <Route path="/reps" element={<Reps />} />
        <Route path="/allmylinks" element={<Links />} />
        <Route path="/sami" element={<Test />} />
        <Route path="/samitwo" element={<TestAgain />} />
        <Route path="/mysamibaby" element={<Test3 />} />
      </Routes>
    </NextUIProvider>
  );
}

export default App;
