import Layout from "../comps/container.jsx";
import ReviewComponent from "../comps/reviews.jsx";
import {
  Image,
  Container,
  Text,
  Grid,
  Card,
} from "@nextui-org/react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const carouselImagesObj = [
  {
    imageUrl: "/images/lucki.jpg",
    id: "1",
  },
  {
    imageUrl: "/images/hypertheory2.jpg",
    id: "2",
  },
  {
    imageUrl: "/images/emilio1.jpg",
    id: "4",
  },
  {
    imageUrl: "/images/nfl1.png",
    id: "5",
  },
  {
    imageUrl: "/images/jake.jpg",
    id: "6",
  },
  {
    imageUrl: "/images/emilio2.jpg",
    id: "7",
  },
  {
    imageUrl: "/images/hypertheory3.png",
    id: "8",
  },
  {
    imageUrl: "/images/luka.png",
    id: "9",
  },

  {
    imageUrl: "/images/cops.png",
    id: "10",
  },
  {
    imageUrl: "/images/flstudio.png",
    id: "11",
  },
  {
    imageUrl: "/images/squadwipe.jpg",
    id: "12",
  },
  {
    imageUrl: "/images/shooting2.png",
    id: "13",
  },
  {
    imageUrl: "/images/hypertheory1.jpg",
    id: "14",
  },
  {
    imageUrl: "/images/clo1.jpg",
    id: "15",
  },
  {
    imageUrl: "/images/jumpshot.jpg",
    id: "16",
  },
  {
    imageUrl: "/images/jaydn.jpg",
    id: "17",
  },
  {
    imageUrl: "/images/bmang1.png",
    id: "18",
  },
  {
    imageUrl: "/images/bmang2.png",
    id: "19",
  },
  {
    imageUrl: "/images/steph.jpg",
    id: "20",
  },
  {
    imageUrl: "/images/chris.png",
    id: "21",
  },
];

const numbersLst = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
numbersLst.map((poo) => poo + 100);

function App(props) {
  
  // mapping a new array called CarouselImages
  const CarouselImages = carouselImagesObj.map(
    (
      imageObj //imageObj is the current element that is being looked at in carouselImagesObj
    ) => (
      <div>
        <Image
          width={640}
          height={360}
          src={imageObj.imageUrl}
          key={imageObj.id}
        />
      </div>
    )
  );
  return (
    <Layout>
      <Container lg>
        <Container css={{ mt: "50px", textAlign: "center" }}>
          <Text h1 size="80px" css={{ mb: "0px" }}>
            Thumbnails
          </Text>
          <Text size="25px" color="$gray700" css={{ mt: "-15px" }}>
            You can view some of my previous work below:
          </Text>
        </Container>
        <Container css={{ mt: "200px", textAlign: "left" }}>
          <Grid.Container gap={1}>
            <Grid lg>
              <Container css={{ ml: "-50px" }}>
                <Text h1 css={{ mb: "0px", mr: "120px", textAlign: "left" }}>
                  Why choose me?
                </Text>
                <Text size="20px" color="$gray700">
                  My thumbnails are proven to increase your CTR and help you
                  build your YouTube audience, my years of Photoshop experience
                  mean I can create any type of style you need. You may view
                  some of my previous commission based work on the right.
                </Text>
              </Container>
            </Grid>
            <Grid css={{}}>
              <Text></Text>
              <Container
                css={{ display: "flex", alignItems: "right", mr: "-50px" }}
              >
                <Card
                  css={{
                    paddingRight: "50px",
                    background: "rgba(60, 0, 74, 0.278)",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.448)",
                  }}
                >
                  <Card.Header
                    css={{ display: "flex", justifyContent: "center" }}
                  >
                    {/* <Text h3 bold css={{textGradient: "45deg, #00e1ff -20%, #bf00ff 50%", display: "flex", justifyContent: "center" }}>Thumbnail Gallery</Text> */}
                  </Card.Header>
                  <Carousel
                    width={700}
                    showStatus={false}
                    infiniteLoop={true}
                    showThumbs={false}
                  >
                    {CarouselImages}
                  </Carousel>
                </Card>
              </Container>
            </Grid>
          </Grid.Container>
        </Container>
        <ReviewComponent>
          
        </ReviewComponent>
      </Container>
    </Layout>
  );
}

export default App;
