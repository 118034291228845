import { Button } from "@nextui-org/react";

import { useNavigate } from "react-router-dom";
// const move = (id) => {
//   const element = document.getElementById(id);
//   console.log("Yeah");
//   element?.scrollIntoView({
//     behavior: "smooth",
//     block: "start",
//   });
// };

const ButtonComponent = (props) => {
  return (
    <Button
      onClick={props.click}
      size={props.size}
      //onClick={move("scroll-me")}
      ref={props.ref}
      css={{
        background: "$gradientBlurple",
        backgroundPosition: "right",
        backgroundSize: "300%",
        marginTop: "30px",
        boxShadow: "0 5px 15px rgba(6, 93, 255, 0.4)",

        "&:hover": {
          backgroundPosition: "right",
          transition: "0.5s",
          boxShadow: "0 5px 15px rgba(6, 122, 255, 0.7)",
        },
      }}
    >
      {props.name}
    </Button>
  );
};

export default ButtonComponent;
