import { Button } from "@nextui-org/react";

const ButtonRep = ({click, name}) => (
  <Button
    onClick={click}
    color="gradient"
    bordered
    auto
    css={{
      color: "#ffffff",
      marginTop: "15px",
      boxShadow: "0 5px 15px rgba(184, 6, 255, 0.4)",
      "&:hover": {
        transition: "0.5s",
        color: "#ba43ff",
        boxShadow: "0 5px 15px rgba(193, 6, 255, 0.7)",
      },
    }}
  >
    {name}
  </Button>
);

export default ButtonRep;
