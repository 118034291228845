import {
  Image,
  Container,
  Text,
  Grid,
  Input,
  Spacer,
  Textarea,
} from "@nextui-org/react";

import ConfettiButton from "../comps/confettibutton";
import ButtonComponent from "../comps/buttons";
import ButtonComponentOutline from "../comps/buttonoutline";
import ThumbnailComponent from "../comps/thumbnail";
import Layout from "../comps/container.jsx";

import { useNavigate } from "react-router-dom";

import {
  AiOutlineThunderbolt,
  AiOutlineRocket,
  AiOutlineYoutube,
  AiOutlineSketch,
} from "react-icons/ai";
import ReviewComponent from "../comps/reviews";

const move = (id) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

// import { AnimationOnScroll } from "react-animation-on-scroll";
// import "animate.css/animate.min.css";
function App(props) {
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <Container
          css={{
            mt: "150px",
            textAlign: "center",
          }}
          lg
        >
          <Image
            width={200}
            height={200}
            src="/b.png"
            alt="picture"
            objectFit="cover"
          />
          <Text h1> Hello, I'm Bahpu. </Text>
          <Text size={"$2xl"} color="$gray700">
            I'm a freelance thumbnail creator, level designer, video editor and
            an aspiring software developer.
          </Text>
          <Text size={"20px"} color="$gray500" css={{ mt: "15px" }}>
            *This site is currently in development, so if you see come across
            any problems please let me know.
          </Text>
          <Text size={"20px"} color="$gray500" css={{ mt: "15px" }}>
            *Mobile support coming soon
          </Text>
          <Container
            fluid
            css={{
              display: "flex",
              m: "0",
              p: "0",
              gap: "40px",
              justifyContent: "center",
            }}
          >
            <ButtonComponent
              // move="scroll-me"
              click={() => {
                move("services");
              }}
              size="xl"
              name={"Check out my work"}
            />
            <ButtonComponentOutline
              name={"Contact me"}
              click={() => {
                move("contact-me");
              }}
            />
          </Container>
          <div id="services"></div>
          <Container css={{ mt: "300px" }}>
            <Text h1 css={{}}>
              {" "}
              All my services.
            </Text>
            <Text size={25} color="$gray700" css={{}}>
              You can check out all the services that I provide below, as well
              as view some of my past work in the respective fields.
            </Text>
          </Container>

          <Container
            css={{ mt: "100px", display: "flex", justifyContent: "center" }}
          >
            <Grid.Container gap={1}>
              <Grid lg>
                <Container>
                  <Text h1 css={{ textAlign: "left" }}>
                    Thumbnails
                  </Text>
                  <Text
                    size={20}
                    color="$gray700"
                    css={{ mr: "120px", textAlign: "left" }}
                  >
                    Eye-catching vibrant thumbnails that are proven to increase
                    your CTR to help grow and expand your channel.
                  </Text>
                  <ButtonComponent
                    size="xl"
                    name={"View Page"}
                    click={() => navigate("/thumbnails")}
                  />
                </Container>
              </Grid>
              <Grid css={{ alignItems: "right", marginRight: "-50px" }}>
                <Text></Text>
                <Container css={{ display: "flex", flexDirection: "row" }}>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/emilio1.jpg"
                    />
                  </div>

                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/hypertheory2.jpg"
                    />
                  </div>
                </Container>
                <Container css={{ display: "flex", flexDirection: "row" }}>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/luka.png"
                    />
                  </div>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/nfl1.png"
                    />
                  </div>
                </Container>
              </Grid>
            </Grid.Container>
          </Container>

          <Container css={{ mt: "350px" }}>
            <Grid.Container gap={0}>
              <Grid css={{ alignItems: "left", marginLeft: "-50px" }}>
                <Text></Text>
                <Container css={{ display: "flex", flexDirection: "row" }}>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/xen.jpg"
                    />
                  </div>

                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="images/geometric.png"
                    />
                  </div>
                </Container>
                <Container css={{ display: "flex", flexDirection: "row" }}>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="images/tf2.png"
                    />
                  </div>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="images/shuttle.jpg"
                    />
                  </div>
                </Container>
              </Grid>
              <Grid lg>
                <Text h1 css={{ textAlign: "right" }}>
                  Source engine level design
                  <Text size={20} color="$gray700" css={{ ml: "100px" }}>
                    My 6 years of experience with designing levels and maps has
                    given me the versatility to create anything you'd like to
                    keep players engaged, entertained and amazed.
                  </Text>
                  <Container
                    fluid
                    css={{
                      display: "flex",
                      m: "0",
                      p: "0",
                      gap: "40px",
                      justifyContent: "right",
                    }}
                  >
                    <ButtonComponent
                      click={() => navigate("/mapping")}
                      size="xl"
                      name={"View Page"}
                    />
                  </Container>
                </Text>
              </Grid>
            </Grid.Container>
          </Container>
          <Container css={{ mt: "350px" }}>
            <Grid.Container gap={0}>
              <Grid lg>
                <Text h1 css={{ textAlign: "left", mr: "60px" }}>
                  Video Editing
                  <Text size={20} color="$gray700">
                    Video editing styles that are assured to keep viewers
                    entertained and hooked, no matter the platform.
                  </Text>
                  <ButtonComponent
                    click={() => navigate("/editing")}
                    size="xl"
                    name={"View Page"}
                  />
                </Text>
              </Grid>
              <Grid css={{ alignItems: "right", marginRight: "-50px" }}>
                <Text></Text>
                <Container css={{ display: "flex", flexDirection: "row" }}>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/tf2_vid_final.png"
                    />
                  </div>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/tekken_final.png"
                    />
                  </div>
                </Container>
                <Container
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    animationPlayState: "paused",
                  }}
                >
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/PJW1_final.png"
                    />
                  </div>
                  <div className="image-test">
                    <ThumbnailComponent
                      height={320}
                      width={180}
                      image="/images/PUBG_final.png"
                    />
                  </div>
                </Container>
              </Grid>
            </Grid.Container>
          </Container>
          {/* My interest in coding began years ago, I'm currently studying
                  computer science at university, you can view some of the mini
                  projects I have been working on below! */}
          <Container css={{ mt: "350px " }}>
            {/* <Grid.Container gap={2}>
              <Grid lg>
                <CardComponent
                  icon={<AiOutlineRocket color="#c052ff" size="25px" />}
                  title="Thumbnails"
                  text="My thumbnails are proven to increase your CTR and help you build your YouTube audience, my years of Photoshop experience mean I can create any type of style you need."
                  button="View Thumbnails"
                  click={() => navigate("/thumbnails")}
                ></CardComponent>
              </Grid>
              <Grid lg>
                <CardComponent
                  icon={<AiOutlineYoutube color="#c052ff" size="25px" />}
                  title="Video Editing"
                  text="Video editing styles that are assured to keep viewers entertained and hooked, no matter the platform."
                  button="View Video Edits"
                  click={() => navigate("/editing")}
                ></CardComponent>
              </Grid>
              <Grid lg>
                <CardComponent
                  icon={<AiOutlineThunderbolt color="#c052ff" size="25px" />}
                  title="Source Engine Level Design"
                  text="I have been using Source/Hammer for several years now. I have made countless mapping projects and amassed over 200,000 downloads on the Steam Workshop."
                  button="View Maps"
                  click={() => navigate("/mapping")}
                ></CardComponent>
              </Grid>
              <Grid lg>
                <CardComponent
                  icon={<AiOutlineSketch color="#c052ff" size="25px" />}
                  title="Coding"
                  text="My interest in coding began years ago, I'm currently studying
                  computer science at university, you can view some of the mini
                  projects I have been working on below!"
                  button="View Coding Projects"
                  click={() => navigate("/projects")}
                ></CardComponent>
              </Grid>
            </Grid.Container> */}
            <ReviewComponent css={{ mt: "0px " }}></ReviewComponent>

            <Container id="contact-me" css={{ mt: "200px " }}>
              <Text h1>Contact Me</Text>
              <Text color="$gray600">
                If you would like to get in touch with me, please use the form
                below.
              </Text>
              <Text color="$gray600" css={{ mt: "10px" }}>
                If you found this site via social media, feel free to contact me
                on the respective platform!
              </Text>
              <Spacer y={1.5} />
              <form
                action="https://formsubmit.co/1275dc18182e15a86c27de670d79b58e"
                method="POST"
              >
                <Input
                  bordered
                  round
                  color="secondary"
                  fullWidth
                  name="name"
                  labelPlaceholder="Name"
                />
                <Spacer y={1.5} />
                <Input
                  bordered
                  round
                  color="secondary"
                  fullWidth
                  type="email"
                  name="email"
                  labelPlaceholder="Email"
                />
                <Spacer y={1.5} />
                <Textarea
                  bordered
                  round
                  color="secondary"
                  fullWidth
                  name="message"
                  Placeholder=""
                  css={{ mb: "100px" }}
                />
                <input type="hidden" name="_captcha" value="false" />
                <input type="hidden" name="_next" value="https://bahpu.co" />
                <Container
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "-70px",
                    mb: "100px",
                  }}
                >
                  <ConfettiButton name="Send message"></ConfettiButton>
                </Container>
              </form>
            </Container>
          </Container>
        </Container>
      </Layout>
    </>
  );
}

export default App;
