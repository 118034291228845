import Layout from "../comps/container.jsx";
import { Image, Container, Text, Grid, Card } from "@nextui-org/react";

import ReviewComponent from "../comps/reviews.jsx";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import MappingCard from "../comps/mapcard";
const carouselImagesObj = [
  {
    imageUrl: "/images/xen.jpg",
    id: "1",
  },
  {
    imageUrl: "/images/tf2.png",
    id: "2",
  },
  {
    imageUrl: "/images/geometric.png",
    id: "4",
  },
  {
    imageUrl: "/images/portal.jpg",
    id: "5",
  },
  {
    imageUrl: "/images/geometric2.png",
    id: "6",
  },
  {
    imageUrl: "/images/xenfy.jpg",
    id: "7",
  },
  {
    imageUrl: "/images/bmang.jpg",
    id: "8",
  },
  {
    imageUrl: "/images/dustwars.jpg",
    id: "9",
  },
  {
    imageUrl: "/images/shuttle.jpg",
    id: "10",
  },
];

function App(props) {
  const CarouselImages = carouselImagesObj.map(
    (
      imageObj //imageObj is the current element that is being looked at in carouselImagesObj
    ) => (
      <div>
        <Image
          width={640}
          height={360}
          src={imageObj.imageUrl}
          key={imageObj.id}
        />
      </div>
    )
  );
  return (
    <Layout>
      <Container css={{ mt: "50px", textAlign: "center" }}>
        <Text h1 size="80px" css={{ mb: "0px" }}>
          Source Level Design
        </Text>
        <Text size="25px" color="$gray700" css={{ mt: "-15px" }}>
          You can view some of my previous mapping projects below:
        </Text>
      </Container>
      <Container>
        <Container css={{ mt: "200px", textAlign: "left" }}>
          <Grid.Container gap={1}>
            <Grid lg>
              <Container css={{ ml: "-50px" }}>
                <Text h1 css={{ mb: "0px", mr: "10px", textAlign: "left" }}>
                  Years of mapping experience.
                </Text>
                <Text size="20px" color="$gray700">
                  I have been using Source/Hammer for several years now, I have
                  made countless mapping projects and amassed over 100,000
                  downloads on the Steam Workshop, some of these maps are
                  available for download with the links below.
                </Text>
                <Text css={{ mt: "20px" }} size="20px" color="$gray700">
                  If you need any type of custom map edits, I will most
                  definitely be able to provide.
                </Text>
              </Container>
            </Grid>
            <Grid css={{}}>
              <Text></Text>
              <Container
                css={{ display: "flex", alignItems: "right", mr: "-50px" }}
              >
                <Card
                  css={{
                    paddingRight: "50px",
                    background: "rgba(60, 0, 74, 0.278)",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.448)",
                  }}
                >
                  <Card.Header
                    css={{ display: "flex", justifyContent: "center" }}
                  >
                    {/* <Text h3 bold css={{textGradient: "45deg, #00e1ff -20%, #bf00ff 50%", display: "flex", justifyContent: "center" }}>Thumbnail Gallery</Text> */}
                  </Card.Header>
                  <Carousel
                    width={700}
                    showStatus={false}
                    infiniteLoop={true}
                    showThumbs={false}
                  >
                    {CarouselImages}
                  </Carousel>
                </Card>
              </Container>
            </Grid>
          </Grid.Container>
        </Container>
        <Container>
          <Container
            css={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: "150px",
            }}
          >
            <Text h1>Some of&nbsp;</Text>
            <Text h1 css={{}}>
              my&nbsp;
            </Text>
            <Text
              h1
              css={{ textGradient: "-70deg, #ff007b -10%, #ff00d4 100%" }}
            >
              previous projects:
            </Text>
            <Grid.Container gap={2} justify="center">
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Outback (Aim)"
                  avatar="/images/tf2.png"
                  text="A CSGO aim map using ported models/textures to imitate the TF2 art style."
                  button="Play Now"
                  click={() =>
                    (window.location.href =
                      "https://steamcommunity.com/sharedfiles/filedetails/?id=2581290320")
                  }
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Geometric (Aim)"
                  avatar="/images/geometric.png"
                  text="A CSGO aim map with unique visuals inspired from TEKKEN's geometric plane."
                  button="Play Now"
                  click={() =>
                    (window.location.href =
                      "https://steamcommunity.com/sharedfiles/filedetails/?id=1989494969")
                  }
                />
              </Grid>
              <Grid md={4} css={{}}>
                {" "}
                <MappingCard
                  name="Shuttle (Aim)"
                  avatar="/images/shuttle.jpg"
                  text="A CSGO aim map based inside a space shuttle with a few easter eggs dotted around."
                  button="Play Now"
                  click={() =>
                    (window.location.href =
                      "https://steamcommunity.com/sharedfiles/filedetails/?id=1853963582")
                  }
                />
              </Grid>

              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Dustwars 2022 Remake (FY)"
                  avatar="/images/dustwars.jpg"
                  text="A complete remake of the popular 'fun_dustwars' CSS map, with up to date assets/models from Black Mesa. "
                  button="Play Now"
                  click={() =>
                    (window.location.href =
                      "https://gamebanana.com/mods/400376")
                  }
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Switch (Aim)"
                  avatar="/images/switch.png"
                  text="A simple CSGO aim map using blue and red colours to signify the two halves of the map."
                  button="Play Now"
                  click={() =>
                    (window.location.href =
                      "https://steamcommunity.com/sharedfiles/filedetails/?id=1843527274")
                  }
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Portal (Aim)"
                  avatar="/images/portal.jpg"
                  text="A CSGO aim using ported models/textures to imitate the Portal art style, with easter eggs dotted around."
                  button="In Development (Coming soon)"
                  disabled={true}
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Test Day (Solo Campaign)"
                  avatar="/images/bmang.jpg"
                  text="A Half-Life 2 solo campaign in which you are called up to run tests for a government aided research facility."
                  button="In Development (Coming soon)"
                  disabled={true}
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Xen (FY)"
                  avatar="/images/xenfy.jpg"
                  text="A CSS FY map based in Black Mesa's Xen, with beautiful up to date visuals/textures/models. "
                  button="In Development (Coming soon)"
                  disabled={true}
                />
              </Grid>
              <Grid md={4}>
                {" "}
                <MappingCard
                  name="Xen (Aim)"
                  avatar="/images/xen.jpg"
                  text="A CSGO Aim map based in Black Mesa's Xen, with beautiful up to date visuals/textures/models. "
                  button="In Development (Coming soon)"
                  disabled={true}
                />
              </Grid>
            </Grid.Container>
          </Container>
        </Container>
        <Container css={{}}></Container>
        <ReviewComponent></ReviewComponent>
      </Container>
    </Layout>
  );
}

export default App;
