import { Button, Card, Text, Image } from "@nextui-org/react";
const MapComponent = ({
  pop,
  avatar,
  icon,
  title,
  text,
  button,
  name,
  disabled,
  click,
}) => {
  return (
    <Card
      css={{
        background: "rgba(255, 255, 255, 0.05)",
        "&:hover": {
          backgroundPosition: "right",
          transform: "scale(1.04)",
          transition: "0.3s",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.448)",
        },
      }}
    >
      <Card.Header
        css={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div>
            <Text
              h3
              css={{ textGradient: "-50deg, #5500ff -10%, #ff00d4 100%" }}
            >
              {name}
            </Text>
          </div>
          <div>
            <Image width="360" height="200" src={avatar} />
          </div>
        </div>
        {icon}
        <Text b css={{ ml: "5px", display: "flex", justifyContent: "center" }}>
          {title}
        </Text>
      </Card.Header>
      <Card.Divider />
      <Card.Body css={{ display: "flex", flex: "column" }}>
        <Text
          size={16}
          color="$gray800"
          css={{ mb: "20px", textAlign: "center" }}
        >
          {text}
        </Text>
        <Button
          onClick={click}
          disabled={disabled}
          size="sm"
          bordered
          color="gradient"
          rounded
          css={{
            mr: "30px",
            ml: "30px",
            mt: "auto",
            display: "flex",
            textAlign: "center",
          }}
        >
          {button}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default MapComponent;
