import Layout from "../comps/container.jsx";
import ButtonRep from "../comps/buttonrep";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Text,
} from "@nextui-org/react";

function App(props) {
    const navigate = useNavigate();
    return (
        <Layout>
            <Container css={{ mt: "50px", textAlign: "center" }}>
                <Text h2 size="40px">
                    Weird/cool accessories rep list
                </Text>
                <Text css={{ color: "$gray700", mt: "-10px" }}>
                    Bunch of random stuff I found that might be cool to buy, updated regularly so keep checking.
                </Text>
                <Container css={{ maxWidth: "400px", mb: "600px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D653039451714&utm_source=pop&utm_medium=pdb&utm_campaign=normal')} name="Supreme Playing Cards"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D634959757581&l')} name="Among Us Plushes"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/shopdetail?t=taobao&id=shop114035267&spi=114035267&sli=2265214782')} name="Supreme Accessories"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D642602028981')} name="Food Keyrings (???)"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D558891773908')} name="Minecraft Lights"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/shopdetail?t=taobao&id=shop115908746&spi=115908746&sli=1910621220')} name="Minecraft Stuff"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D665115820982')} name="Puffer Jacket Case"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D662267657688 ')} name="Stussy Dice Keyrings"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D616160126320')} name="Sesame Street"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D681653825741')} name="Ken Carson Goomba"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D640894022440 ')} name="Literal Bitcoins"></ButtonRep>
                    <ButtonRep click={() => window.open('https://pandabuy.com/shopdetail?t=taobao&id=shop315341251&spi=315341251&sli=2200719519989')} name="Funko Pops"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D665984014614')} name="Capybara"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D668246407766')} name="Unopened Chief Keef Finally Rich (???)"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D660357884291')} name="San Andreas Tee"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D679787993913')} name="Lego Keyrings"></ButtonRep>
                    <ButtonRep click={() => window.open('https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D619057870951')} name="Lego Minifigures"></ButtonRep>
                    <ButtonRep click={() => window.open("https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D676349102181&")} name="Pokemon Cards"></ButtonRep>
                    <ButtonRep click={() => window.open("https://www.pandabuy.com/product?url=https%3A%2F%2Fitem.taobao.com%2Fitem.htm%3Fid%3D695781321641")} name="Hamster (??????)"></ButtonRep>

                </Container>
            </Container>
        </Layout>
    );
}

export default App;
