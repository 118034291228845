import {
  Button,
  Card,
  Text,
  Avatar,
  extendedColorsNoGradient,
} from "@nextui-org/react";
const ClientComponent = ({ pop, avatar, icon, title, text, button, click }) => {
  return (
    <Card 
      css={{
        background: "rgba(255, 255, 255, 0.05)",
        "&:hover": {
          backgroundPosition: "right",
          transform: "scale(1.04)",
          transition: "0.3s",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.448)",
        },
      }}
    >
      <Card.Header
        css={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Avatar src={avatar} css={{ size: "$20" }} />
        {icon}
        <Text b css={{ ml: "5px", display: "flex", justifyContent: "center" }}>
          {title}
        </Text>
      </Card.Header>
      <Card.Divider />
      <Card.Body css={{ display: "flex", flex: "column" }}>
        <Text
          size={16}
          color="$gray800"
          css={{ mb: "20px", textAlign: "center" }}
        >
          {text}
        </Text>
        <Button
          size="sm"
          bordered
          color="gradient"
          rounded
          onClick={click}
          css={{ mr:"30px", ml:"30px", mt: "auto", display: "flex", textAlign: "center" }}
        >
          {button}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ClientComponent;
