import styles from "./test.module.css"
import { IoIosWarning } from "react-icons/io";
import { useState, useRef } from "react";
import { Container, Image, Navbar, Text, Link } from "@nextui-org/react";


export default function Test() {

    const [moneyArray, setMoneyArray] = useState([]);
    const [promptVisible, setPromptVisible] = useState(true);
    const words = ["💞", "❤️", "i love you"]
    let audio = new Audio("/dansen.mp3")
    const audioRef = useRef(null);

    const handleClick = (e) => {
        var Xlocation = e.clientX - 70; // Get client's X and Y coordinates on click
        var Ylocation = e.clientY;
        const randomNum = Math.floor(Math.random() * 3);
        const addNewElement = () => {
            const angle = Math.random() * 2 * Math.PI;

            // Define the distance to move (in pixels)
            const distance = 100;

            // Calculate new X and Y positions using trigonometry
            const newX = Xlocation + Math.cos(angle) * distance;
            const newY = Ylocation + Math.sin(angle) * distance;

            // Create the new money element
            const newElement = (
                <div className={styles.money} style={{ top: newY, left: newX }}>
                    {words[randomNum]}
                </div>
            );
            setMoneyArray(prevMoneyArray => [...prevMoneyArray, newElement]); // Add the new element to the moneyArray
        };

        // Call the addNewElement function 10 times over 10 seconds
        const intervalId = setInterval(() => {
            addNewElement();
        }, 50);

        // Stop adding new elements after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 4000);
    };

    const handleButtonClick = () => {
        setPromptVisible(false);
        audio.volume = 0.2;
        audio.play();
        audioRef.current = audio;
        // setTimeout(() => {
        //     setPromptVisible(true);
        // }, 500); // Adjust the time according to your animation duration
    };

    const handleNo = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audio.volume = 0;
        window.open('https://youtu.be/eVTXPUF4Oz4?feature=shared&t=48');

    }
    return (
        <>
            <div className={styles.QUARTERIZE}>
                <div style={{ top: 0, left: 0, position: "absolute", zIndex: "9999" }}>{moneyArray}</div>
                <Image
                    src="/BGKITY.jpg"
                    objectFit="cover"
                    autoResize
                    css={{ position: "fixed", padding: 0, zIndex: 1, left: 0, top: 0, zIndex: 0, backgroundSize: "150%g" }}
                />
                <div className={styles.mainContainer}>
                    <div className={styles.toolbar}> <div className={styles.imageHelloKitty}></div></div>
                    {promptVisible ? (<div className={`${styles.mainBox} ${!promptVisible ? styles['fade-out'] : ''}`}>
                        <div className={styles.textContainer}>
                            <div> You have 1 New Message !! </div>
                            <div className={styles.outlineButton} onClick={handleButtonClick}>
                                Open Message
                            </div>
                        </div>
                    </div>) : (<div className={`${styles.newPrompt} ${styles['fade-in']}`}>
                        <div className={styles.newPromptContainer}>
                            <div className={styles.newPromptAvatar}>
                                <img src="/arjun.png" />
                            </div>
                            <div className={`${styles.bubble} ${styles.sb3}`}>
                                <div className={styles.headerPrompt}>Arjun Says:</div>
                                "Will you be my romantic valentine...? since u already have a platonic one. or whatever. um."
                                <div className={styles.newPromptButtonContainer}>
                                    <div className={styles.outlineButton} onClick={handleClick}> Yes !!</div>
                                    <div className={styles.outlineButton} onClick={handleNo}>&nbsp;No.&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </>
    )
}