import Layout from "../comps/container.jsx";
import {
  Container,
  Text,
} from "@nextui-org/react";


function App(props) {
  return (
    <Layout>
      <Container css={{ mt: "50px", textAlign: "center" }}>
        <Text h1 size="80px">
          Projects
        </Text>
        <Text size="30px" color="$gray800" css={{mb:"500px"}}>
          This page is currently under construction, it will include some of my
          programming projects converted to work with this website! Stay tuned.
        </Text>
      </Container>
      {/* <Container css={{ mt: "50px", textAlign: "center" }}>
      <Text h1 size="50px" css={{mt:"150px"}}>
          Some of my unconverted projects:
        </Text>
      </Container> */}
    </Layout>
  );
}

export default App;
