import { Container, Grid, Text } from "@nextui-org/react";
import ClientCard from "../comps/clientcard";

const ReviewComponent = (onClick) => {
  return (
    <>
      <Container
        css={{
          mt: "150px",
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Container
          css={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            textGradient: "-70deg, #3b3eff -10%, #aa49ff 50%, #f530ff 100%",
          }}
        >
          <Text h1>What&nbsp;</Text>
          <Text h1 css={{}}>
            some users&nbsp;
          </Text>
          <Text h1>have to say about my services:</Text>
        </Container>
      </Container>
      <Container>
        <Container css={{ mb: "100px" }}>
          <Grid.Container gap={2} justify="center">
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/jah.png"
                text="'Went in looking for 2 thumbnails, great communication (at 4am EST on my side) and both were great thumbnails! Vibrant, straight to the point, and looked well next to other channels vids under the same search.'"
                button="Jah's YouTube"
                click={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCmiBVXjWvPJzDjRjBsiyYxA"
                  )
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/projectp.jpg"
                text="'I wanna start by saying the end product was amazing and whole process in general was a breeze as a creator it comes with a lot of difficulties when trying to outsource other work but with Bahpu everything was fast simple and smooth. The finished product was amazing and to this date is still one of my most viewed gaming videos'"
                button="Project P's YouTube"
                click={() => window.open("https://www.youtube.com/@ProjectP.")}
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/jd.jpg"
                text="'Bahpu does great work. Have used him on several projects and he was able to send me different versions of each project to choose from which I appreciated. Bahpu and I will continue to work together as I watch my channel grow. I recommend him to anyone looking for good work.'"
                button="JD's YouTube"
                click={() => window.open("https://www.youtube.com/@36mike1986")}
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/jaydn_av.jpg"
                text="'IFW Bahpu's work he works very fast, everything is great quality and you can't beat his prices for the work your receiving.'"
                button="Jaydvn's YouTube"
                click={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCje4Wo6e4W4NbGV3cHmMPkw"
                  )
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/mano.png"
                text="'Bahpu did a fantastic job on my thumbnails I used for YouTube. He has
              quality cheap service + quick speed services also, I hit him up for a
              thumbnail with information about it, he usually finishes 25 minutes -
              1 Hour! And BOOM, I'm able to upload my YouTube video to my
              subscribers on time. Good Services!'"
                button="Mano's YouTube"
                click={() =>
                  window.open("https://www.youtube.com/@manonotmono")
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/dlayed.png"
                text="'Edited multiple videos for me and the results were always high quality and I recieved them quickly after requesting them'"
                button="DLAYed's Steam"
                click={() =>
                  window.open(
                    "https://steamcommunity.com/profiles/76561198162880792/"
                  )
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/acro.png"
                text="'Always solid and professional services provided by Bahpu, recommended for anyone in need of some high quality thumbnails/edits!'"
                button="Acro's Twitch"
                click={() => window.open("https://www.twitch.tv/acrobatmorf")}
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/clo.jpg"
                text="'Bahpu is my go to man. His turn around times are fast and he is very communicative and makes sure I'm satisfied with my service. I will continue to go to him for thumbnails and other services. 10/10 recommended.'"
                button="Clo's YouTube"
                click={() =>
                  window.open(
                    "https://www.youtube.com/channel/UC7l68dq8XyqNSv22dkc-UjA"
                  )
                }
              />
            </Grid>

            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/Homer.png"
                text="'Needed a few things doing in a short amount of time, got back to me with great quality and a short time. Will be using again.
              '"
                button="Joel's Steam"
                click={() =>
                  window.open("https://steamcommunity.com/id/Joelptay/")
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/Cam.jpg"
                text="'Quality service and very quick return time. My go-to guy every time.'"
                button="Cam's YouTube"
                click={() =>
                  window.open("https://www.youtube.com/c/HoodieCamBeats")
                }
              />
            </Grid>
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/99rise.jpg"
                text="'Very satisfied with what I requested. He has a particular style and it was what i was looking for. Services are surprisingly cheap.'"
                button="99rise's Twitter"
                click={() => window.open("https://twitter.com/99rrise")}
              />
            </Grid>

            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/emilio_pfp.png"
                text="'10/10, always gets the job done and gets it done fast.'"
                button="Emilio's YouTube"
                click={() => window.open("https://www.youtube.com/@p23emilio")}
              />
            </Grid>
            {/* <Grid md={4}>
            <ClientCard
              avatar="/images/avatars/clo.jpg"
              text="'Very satisfied with what I requested. He has a particular style and it was what i was looking for. Services are surprisingly cheap.'"
              button="Clo's YouTube"
              click={() =>
                window.open(
                  "https://www.youtube.com/channel/UC7l68dq8XyqNSv22dkc-UjA"
                )
              }
            />
          </Grid> */}
            <Grid md={4}>
              <ClientCard
                avatar="/images/avatars/Riox.png"
                text="'Really great map! Enjoyed playing it as it is very polished and has a great design for the purpose.'"
                button="Riox's Steam"
                click={() =>
                  window.open("https://steamcommunity.com/id/rioxcs/")
                }
              />
            </Grid>
          </Grid.Container>
        </Container>
      </Container>
    </>
  );
};
export default ReviewComponent;
