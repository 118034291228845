import { Container, Image, Navbar, Text, Link } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <>
      <Container fluid css={{ m: 0, p: 0 }}>
        <Image src="/background.png" objectFit="cover" autoResize css={{ position: "fixed", padding: 0, zIndex: 1, left: 0, top: 0 }} />
      </Container>
      <Container fluid css={{ zIndex: 2, position: "relative", padding: 0, minWidth: "100%" }}>
        <Navbar
          isBordered
          fluid
          variant={"sticky"}
          maxWidth={"fluid"}
          minWidth={"fluid"}
          css={{
            minWidth: "100%",
            m: 0,
            p: 0,
            gap: 0,
            zIndex: "30",
          }}
        >
          <div className="smallbox" onClick={() => navigate("/")}>
            <a href="/">
              <Navbar.Brand>
                <Image src="/b.png" height={50} width={50}></Image>
                <Text b color="inherit" hideIn="xs"></Text>
              </Navbar.Brand>
            </a>
          </div>
          <Text b color="white" hideIn="xs"></Text>
          <Navbar.Content hideIn="xs">
            <Navbar.Link href="/">Home</Navbar.Link>
            <Navbar.Link href="/thumbnails">Thumbnails</Navbar.Link>
            <Navbar.Link href="/editing">Video Editing</Navbar.Link>
            <Navbar.Link href="/mapping">Level Design</Navbar.Link>
            <Navbar.Link href="/projects">Coding Projects</Navbar.Link>
          </Navbar.Content>
        </Navbar>
        <Container xl css={{ padding: "0" }}>
          {children}
        </Container>
        <Container
          fluid
          maxWidth={"fluid"}
          minWidth={"fluid"}
          css={{
            zIndex: "9",
            backgroundColor: "#150e27a2",
            color: "$grey300",
            width: "100%",
            height: "100%",
            m: "0",
            paddingBottom: "30px",
            minWidth: "100%",
            minHeight: "100%",
            boxShadow: "200px 200px 200px 200px #000000d0",
            textAlign: "enter",
          }}
        >
          <Text
            h1
            size={30}
            css={{
              textGradient: "270deg, #00bbff -30%, #ff00ff 100%",
              display: "flex",
              justifyContent: "center",
              mb: "0px",
            }}
          >
            Website designed by Bahpu.
          </Text>
          <Text
            css={{
              display: "flex",
              justifyContent: "center",
              color: "$gray800",
              textAlign: "center",
            }}
          >
            freelance thumbnail creator, level designer, video editor and an aspiring software developer.
          </Text>
        </Container>
      </Container>
    </>
  );
};

export default Layout;
