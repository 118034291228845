import Layout from "../comps/container.jsx";
import {
  Container,
  Text,
  Grid,
} from "@nextui-org/react";

import ReviewComponent from "../comps/reviews.jsx";
import MapComponent from "../comps/mapcard.jsx";
function App(props) {
  return (
    <>
      <Layout>
        <Container css={{ mt: "50px", textAlign: "center" }}>
          <Text h1 size="80px">
            Video Editing
          </Text>
          <Text size="25px" color="$gray700" css={{ mt: "-15px", mb:"150px"}}>
            You can view some of my video editing examples below:
          </Text>
        </Container>
        <Container>
          <Grid.Container
            gap={2}
            justify="center"
            css={{ textAlign: "center" }}
          >
            <Grid md={4}>
              <MapComponent
                name="Project Winter Example #1"
                avatar="/images/PJW1_final2.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=xtrh5kQLUTk")
                }
              ></MapComponent>
            </Grid>
            <Grid md={4}>
              <MapComponent
                name="Gmod Trolling Example"
                avatar="/images/goose_final.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=g4FMTT8hhV0")
                }
              ></MapComponent>
            </Grid>
            <Grid md={4}>
              <MapComponent
                name="TF2 Montage Example"
                avatar="images/tf2_vid_final.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=B4B9ZHcOsgU")
                }
              ></MapComponent>
            </Grid>
            <Grid md={4}>
              <MapComponent
                name="Project Winter Example #2"
                avatar="/images/PJW2_final2.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=sG7wc9oAKvU")
                }
              ></MapComponent>
            </Grid>
            <Grid md={4}>
              <MapComponent
                name="Tekken Montage Example"
                avatar="/images/tekken_final.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=dADKHzPsZ-4")
                }
              ></MapComponent>
            </Grid>
            <Grid md={4}>
              <MapComponent
                name="PUBG Example"
                avatar="/images/PUBG_final.png"
                button="View Video"
                click={() =>
                  window.open("https://www.youtube.com/watch?v=flnMH_WOepE")
                }
              ></MapComponent>
            </Grid>
          </Grid.Container>
        </Container>

        <ReviewComponent></ReviewComponent>
      </Layout>
    </>
  );
}
export default App;
