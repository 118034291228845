import { Image } from "@nextui-org/react";

const ThumbnailComponent = (props) => (
  <Image
    width={props.height}
    height={props}
    objectFit="fill"
    css={{
      animationPlayState: "paused",
      opacity:0.7,
      display:"flex",
      alignItems:"right",
      overflow: "visible",
      boxShadow: "10px 10px 50px 10px #000000d0",
      transition:"0.5s",
      filter:"grayscale(100%)",
      // filter: "contrast(100%) brightness(100%) grayscale(70%) saturate(109%) hue-rotate(95deg) sepia(0%)  invert(0%)",
      // transition:"0.7s",
      "&:hover": {
        // filter: "contrast(100%) brightness(100%) grayscale(0%) saturate(100%) hue-rotate(0deg) sepia(0%)  invert(0%)",
        // transition:"0.7s",
        opacity:1,
        transition:"0.5s",
        filter:"grayscale(0%)",
      }
    }}
    containerCss={{display:"flex", overflow: "visible", justifyContent:"right"}}
    src={props.image}
  ></Image>
);

export default ThumbnailComponent;