import { Button } from "@nextui-org/react";
import confetti from "canvas-confetti";


const handleConfetti = () => {
  confetti({
    origin: {
      x: 0.5,
      y: 0.85
    },
    particleCount:250,
    spread: 180,
    drift: Math.random(10),
  });
}

const ConfettiButton = (props) => (
  <Button
    onClick={handleConfetti}
    type="submit"
    color="gradient"
    bordered
    size="xl"
    css={{
      color: "#ffffff",
      marginTop: "30px",
      boxShadow: "0 5px 15px rgba(184, 6, 255, 0.4)",
      "&:hover": {
        transition: "0.5s",
        color: "#ba43ff",
        boxShadow: "0 5px 15px rgba(193, 6, 255, 0.7)",
      },
    }}
  >
    {props.name}
  </Button>
);

export default ConfettiButton;


